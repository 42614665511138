import { defineStore } from 'pinia'
import { Service } from '@/services/helpers/ServiceHelper'

export const usePrefencesStore = defineStore('preferences', () => {
  const preferences = ref({
    apiUrl: localStorage.getItem('apiUrl') || import.meta.env.VITE_API_ENDPOINT,
    drawerPinned: localStorage.getItem('drawerPinned') === 'true',
    urlShortenerPreference: localStorage.getItem('urlShortenerPreference'),
    alwaysShowReceivedDelivered: localStorage.getItem('alwaysShowReceivedDelivered') === 'true'
  })

  const drawerPinned = () => computed({
    get: () => {
      return preferences.value.drawerPinned
    },
    set: (value: boolean) => {
      localStorage.setItem('drawerPinned', JSON.stringify(value))
      preferences.value.drawerPinned = value
    }
  })

  const apiUrl = () => computed({
    get: () => preferences.value.apiUrl,
    set: (value: string): undefined => {
      localStorage.setItem('apiUrl', value)
      Service.$axiosInstance.defaults.baseURL = value
      preferences.value.apiUrl = value
    }
  })

  const urlShortenerPreference = () => computed({
    get: () => preferences.value.urlShortenerPreference,
    set: (value) => {
      localStorage.setItem('urlShortenerPreference', <string>value)
      preferences.value.urlShortenerPreference = value
    }
  })

  const alwaysShowReceivedDelivered = () => computed({
    get: () => preferences.value.alwaysShowReceivedDelivered,
    set: (value: boolean) => {
      localStorage.setItem('alwaysShowReceivedDelivered', <string>value)
      preferences.value.alwaysShowReceivedDelivered = value
    }
  })

  const askShortenUrls = computed(() => {
    return !preferences.value.urlShortenerPreference
  })
  const alwaysShortenUrls = computed(() => {
    return preferences.value.urlShortenerPreference === 'always'
  })
  const neverShortenUrls = computed(() => {
    return preferences.value.urlShortenerPreference === 'never'
  })

  return {
    editable: {
      apiUrl,
      drawerPinned,
      urlShortenerPreference,
      alwaysShowReceivedDelivered
    },
    urlShortenerPreferences: {
      askShortenUrls,
      alwaysShortenUrls,
      neverShortenUrls
    }
  }
})
